import React, { useEffect, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Row,
    Container
} from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import { LoanFeeFormRoute } from "../../constant/RouteName";
import { useStore1Selector } from "index";
import { loginUser } from "../../Redux/Slices/userSlice";
import usePost from "hooks/usePost";
import { ServerError, UpdateFeeMsg } from "constant/NotifyMessage";
import useFetch from "hooks/useFetch";
import CustomBtn from "components/ui/CustomBtn";
import LoadingAnimation from "components/helper/Loading-animation";
import ErrorPage from "components/helper/ErrorPage";
import Switch from "react-switch";

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            Yes
        </div>
    );
};

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            No
        </div>
    );
};

const LoanFeeForm = () => {

    const { execute, pending } = usePost()
    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;

    const { data: getFee, loading, error } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/fees`, token);
    const feeConfig = getFee && getFee?.length > 0 ? getFee[getFee?.length - 1] : null;

    const [isHiddenLoan, setIsHiddenLoan] = useState(feeConfig?.isHiddenLoan)
    const [feeId, setFeeId] = useState("")

    const [formData, setFormData] = useState({
        thresholdAmount: feeConfig?.thresholdAmount,
        initiationFeeBelowThreshold: feeConfig?.initiationFeeBelowThreshold,
        initiationFeeAboveThresholdFirst1000: feeConfig?.initiationFeeAboveThresholdFirst1000,
        initiationFeeAboveThresholdRemainder: feeConfig?.initiationFeeAboveThresholdRemainder,
        serviceFeePerDay: feeConfig?.serviceFeePerDay,
        interestRatePerDay: feeConfig?.interestRatePerDay
    });

    useEffect(() => {
        if (feeConfig && feeConfig._id !== feeId) {
            setFormData({
                thresholdAmount: feeConfig.thresholdAmount,
                initiationFeeBelowThreshold: feeConfig.initiationFeeBelowThreshold,
                initiationFeeAboveThresholdFirst1000: feeConfig.initiationFeeAboveThresholdFirst1000,
                initiationFeeAboveThresholdRemainder: feeConfig.initiationFeeAboveThresholdRemainder,
                serviceFeePerDay: feeConfig.serviceFeePerDay,
                interestRatePerDay: feeConfig.interestRatePerDay,
            });
            setIsHiddenLoan(feeConfig.isHiddenLoan);
            setFeeId(feeConfig._id);
        }
    }, [feeConfig, feeId]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const Method = 'PATCH', endPoint = `fees/${feeId}`;

        const raw = JSON.stringify({
            thresholdAmount: parseFloat(formData.thresholdAmount),
            initiationFeeBelowThreshold: parseFloat(formData.initiationFeeBelowThreshold),
            initiationFeeAboveThresholdFirst1000: parseFloat(formData.initiationFeeAboveThresholdFirst1000),
            initiationFeeAboveThresholdRemainder: parseFloat(formData.initiationFeeAboveThresholdRemainder),
            serviceFeePerDay: parseFloat(formData.serviceFeePerDay),
            interestRatePerDay: parseFloat(formData.interestRatePerDay),
            isHiddenLoan: isHiddenLoan,
        });

        execute(endPoint, raw, Method, UpdateFeeMsg, token)
    };

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    return (
        <React.Fragment>
            <div className="page-content px-5">
                <Breadcrumb default={LoanFeeFormRoute} defaultName="Fee" title={"Form"} />
                <MetaTag title_sco={"Loan Fee Form"} />

                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h1 className="h1 mb-4">Fees Form</h1>

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-url-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Threshold  Amount
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                name="thresholdAmount"
                                                className="form-control"
                                                type="number"
                                                value={formData.thresholdAmount}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </Row>

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-email-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Initiation Fee Below Threshold
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                name="initiationFeeBelowThreshold"
                                                className="form-control"
                                                type="number"
                                                value={formData.initiationFeeBelowThreshold}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </Row>

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-url-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Initiation Fee Above Threshold First 1000
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                name="initiationFeeAboveThresholdFirst1000"
                                                className="form-control"
                                                type="number"
                                                value={formData.initiationFeeAboveThresholdFirst1000}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </Row>

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-tel-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Initiation Fee Above Threshold Remainder
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                name="initiationFeeAboveThresholdRemainder"
                                                className="form-control"
                                                type="number"
                                                value={formData.initiationFeeAboveThresholdRemainder}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </Row>

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-password-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Service Fee Per Day
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                name="serviceFeePerDay"
                                                className="form-control"
                                                type="number"
                                                value={formData.serviceFeePerDay}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </Row>

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-password-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Interest Rate Per Day
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                name="interestRatePerDay"
                                                className="form-control"
                                                type="number"
                                                value={formData.interestRatePerDay}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </Row>

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-password-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            Is this a private loan ?
                                        </label>
                                        <div className="col-md-10">
                                            <Switch
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                onColor="#626ed4"
                                                onChange={() => {
                                                    setIsHiddenLoan(!isHiddenLoan);
                                                }}
                                                checked={isHiddenLoan}
                                            />
                                        </div>
                                    </Row>

                                    <div className="d-flex justify-content-end">
                                        <div className="w-25 ">
                                            <CustomBtn Pending={pending} btnName="Submit" onClick={handleSubmit} />
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )
}
export default LoanFeeForm