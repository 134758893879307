import { Col, Row } from 'reactstrap'
import { useStore1Selector } from 'index';
import { loginUser } from 'Redux/Slices/userSlice';
import useFetch from 'hooks/useFetch';

function LoanFeeBreakdown() {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const { data } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/fees`, token);

    const feeConfig = data && data.length > 0 ? data[data.length - 1] : null;

    const threshold = feeConfig?.thresholdAmount ?? 1000;
    const initiationBelow = feeConfig?.initiationFeeBelowThreshold ?? 0.15;
    const initiationFirst1000 = feeConfig?.initiationFeeAboveThresholdFirst1000 ?? 0.15;
    const initiationRemainder = feeConfig?.initiationFeeAboveThresholdRemainder ?? 0.1;
    const serviceFeePerDay = feeConfig?.serviceFeePerDay ?? 2;
    const interestRatePerDay = feeConfig?.interestRatePerDay ?? 0.001;

    return (
        <Row>
            <Col md={12}>
                <div className="loan-fee-breakdown p-4 bg-light border rounded">
                    <h2 className="text-center mb-4">Loan Fee Breakdown</h2>

                    {/* Initiation Fee Section */}
                    <div className="fee-item mb-3">
                        <h4 className="fw-bold">Initiation Fee</h4>
                        <p>
                            <strong>For Loan Amounts Less Than or Equal to R{threshold}:</strong>{" "}
                            A flat initiation fee of <strong>{initiationBelow * 100}%</strong> of the loan amount is applied.
                        </p>
                        <p>
                            <strong>For Loan Amounts Greater Than R{threshold}:</strong>{" "}
                            The first R{threshold} is charged at <strong>{initiationFirst1000 * 100}%</strong>,
                            and any amount exceeding R{threshold} is charged at <strong>{initiationRemainder * 100}%</strong>.
                        </p>
                    </div>

                    {/* Service Fee Section */}
                    <div className="fee-item mb-3">
                        <h4 className="fw-bold">Service Fee</h4>
                        <p>
                            A fixed service fee of <strong>R{serviceFeePerDay} per day</strong> is added
                            for each day of the loan period.
                        </p>
                    </div>

                    {/* Interest Fee Section */}
                    <div className="fee-item mb-3">
                        <h4 className="fw-bold">Interest Fee</h4>
                        <p>
                            The interest on your loan is calculated at a rate of{" "}
                            <strong>{interestRatePerDay * 100}% per day</strong>, multiplied by the number of days you choose.
                        </p>
                    </div>

                    <div className="fee-summary mt-4">
                        <h3 className="text-center text-primary">
                            Total Fees = Initiation Fee + Service Fee + Interest Fee
                        </h3>
                        <p className="text-center">
                            This will be added to the loan amount to determine the total amount you need to pay back.
                        </p>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default LoanFeeBreakdown;